<template>
	<edit-template class="page-wrapper white" @confirm="submitForm('ruleForm')" @cancel="resetForm('ruleForm')" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="page-info psychology-page px-3 pt-3">
      <div class="mb-2">域名</div>
      <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="0" label-position="right">
        <el-form-item prop="content">
          <el-input type="textarea" v-model="formData.content" :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
        </el-form-item>
      </el-form>
    </div>
    
  
</edit-template>
</template><script>
import { postApi2 } from '@/api'
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  name: 'Add',
  data() {
    return {
      formData: {
        content: ''
      },
      rules: {
        content: [{ required: true, message: '请输入域名' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          postApi2('/tool/white-list/add', { content: this.formData.content }).then(() => {
            this.$router.back()
          })
        }
      })
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields()
      let that = this
      this.$confirm('确定取消？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.$router.back()
      })
    }
  }
}
</script>
